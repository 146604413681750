

jQuery(window).ready(function() {
     jQuery('#nav-toggle').click(function(){
        jQuery('body').toggleClass("open-menu");

    });
    jQuery('#page').on('click', function () {
        jQuery('body').removeClass('open-menu');

    });
    jQuery('#nav-toggle').on('click', function (e) {
        e.stopPropagation();
    });


//--Owl carousel--//
jQuery('.owl-carousel.home-slider').owlCarousel({
    loop:true,
    items:1,
    autoplay: true,
    dots:false,
    nav:false
});
//--Owl carousel--//

});
